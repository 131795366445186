<template>
  <div class="basic-table">
    <div class="basic-table__header">
      <h3 class="basic-table__title" v-bind:class="titleTextClass">
        {{ titleText }}
      </h3>
    </div>
    <b-container style="margin-top: 10px; margin-bottom: 20px;">
      <!-- Stack the columns on mobile by making one full-width and the other half-width -->
      <b-row align-v="center">
        <b-col class="text-left seize-table-text-bold no-padding">
          <v-switch
            :input-value="filter.termMode"
            :label="filter.termLabel"
            dense
            hide-details
            style="padding-top: 3px; margin: 0;"
            @change="changeTermMode"
          ></v-switch>
          <!--          </v-radio-group>-->
          <!--          기간</b-col>-->
        </b-col>
        <b-col v-if="filter.termMode" class="no-padding">
          <vue-multi-select
            v-model="filter.term"
            :btnLabel="selectLabel"
            :disabled="filterDisabled.term"
            :filters="multiSelectFilters"
            :options="multiSelectOptions"
            :selectOptions="termFilters"
            historyButton
            search
          />
        </b-col>
        <b-col v-if="!filter.termMode" class="no-padding">
          <vue-multi-select
            v-model="filter.month"
            :btnLabel="selectLabel"
            :disabled="filterDisabled.month"
            :filters="multiSelectFilters"
            :options="multiSelectOptions"
            :selectOptions="monthFilters"
            historyButton
            search
          />
        </b-col>
        <b-col class="text-left seize-filter-text no-padding">입출구분</b-col>
        <b-col class="no-padding">
          <vue-multi-select
            v-model="filter.inout"
            :btnLabel="selectLabel"
            :disabled="filterDisabled.inout"
            :filters="multiSelectFilters"
            :options="multiSelectOptions"
            :selectOptions="inoutFilters"
            historyButton
            search
          />
        </b-col>
        <b-col class="text-left seize-filter-text no-padding">대상기관</b-col>
        <b-col class="no-padding">
          <vue-multi-select
            v-model="filter.targetInst"
            :btnLabel="selectLabel"
            :disabled="filterDisabled.targetInst"
            :filters="multiSelectFilters"
            :options="multiSelectOptions"
            :selectOptions="targetInstFilters"
            historyButton
            search
          />
        </b-col>
        <b-col class="text-left seize-filter-text no-padding">성명</b-col>
        <b-col class="no-padding">
          <vue-multi-select
            v-model="filter.name"
            :btnLabel="selectLabel"
            :disabled="filterDisabled.name"
            :filters="multiSelectFilters"
            :options="multiSelectOptions"
            :selectOptions="nameFilters"
            historyButton
            search
          />
        </b-col>
        <b-col class="no-padding">
          <b-button
            v-b-toggle.collapse
            :pressed="true"
            class="seize-base-margin seizeblue-button"
            variant="outline-seizeblue"
            >+ 필터
          </b-button>
        </b-col>
      </b-row>

      <b-collapse id="collapse">
        <b-row align-v="center">
          <b-col class="text-left seize-filter-text no-padding">레벨2</b-col>
          <b-col class="no-padding">
            <vue-multi-select
              v-model="filter.level2"
              :btnLabel="selectLabel"
              :disabled="filterDisabled.level2"
              :filters="multiSelectFilters"
              :options="multiSelectOptions"
              :selectOptions="level2Filters"
              historyButton
              search
            />
          </b-col>
          <b-col class="text-left seize-filter-text no-padding">레벨3</b-col>
          <b-col class="no-padding">
            <vue-multi-select
              v-model="filter.level3"
              :btnLabel="selectLabel"
              :disabled="filterDisabled.level3"
              :filters="multiSelectFilters"
              :options="multiSelectOptions"
              :selectOptions="level3Filters"
              historyButton
              search
            />
          </b-col>
          <b-col class="text-left seize-filter-text no-padding">레벨4</b-col>
          <b-col class="no-padding">
            <vue-multi-select
              v-model="filter.level4"
              :btnLabel="selectLabel"
              :disabled="filterDisabled.level4"
              :filters="multiSelectFilters"
              :options="multiSelectOptions"
              :selectOptions="level4Filters"
              historyButton
              search
            />
          </b-col>
          <b-col class="text-left seize-filter-text no-padding">레벨5</b-col>
          <b-col class="no-padding">
            <vue-multi-select
              v-model="filter.level5"
              :btnLabel="selectLabel"
              :disabled="filterDisabled.level5"
              :filters="multiSelectFilters"
              :options="multiSelectOptions"
              :selectOptions="level5Filters"
              historyButton
              search
            />
          </b-col>
          <b-col class="no-padding"></b-col>
        </b-row>
        <b-row align-v="center">
          <b-col class="text-left seize-filter-text no-padding">거래유형</b-col>
          <b-col class="no-padding">
            <vue-multi-select
              v-model="filter.tranType"
              :btnLabel="selectLabel"
              :disabled="filterDisabled.tranType"
              :filters="multiSelectFilters"
              :options="multiSelectOptions"
              :selectOptions="tranTypeFilters"
              historyButton
              search
            />
          </b-col>
          <b-col class="text-left seize-filter-text no-padding">거래내용</b-col>
          <b-col align-v="center" class="no-padding">
            <v-text-field
              v-model="printContentFilter"
              class="seize-search-text"
              clearable
              outlined
              placeholder="검색어"
              style="padding-right: 0;"
            />
          </b-col>
          <b-col class="text-center seize-filter-text no-padding"
            >거래금액
          </b-col>
          <b-col class="no-padding" cols="4">
            <v-range-slider
              id="input-slider"
              v-model="filter.tranAmt.range"
              :max="filter.tranAmt.max"
              :min="filter.tranAmt.min"
              class="align-center"
              hide-details
            >
              <template v-slot:prepend>
                <v-text-field
                  :value="filter.tranAmt.range[0]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  @change="$set(filter.tranAmt.range, 0, $event)"
                ></v-text-field>
              </template>
              <template v-slot:append>
                <v-text-field
                  :value="filter.tranAmt.range[1]"
                  class="mt-0 pt-0"
                  hide-details
                  single-line
                  type="number"
                  @change="$set(filter.tranAmt.range, 1, $event)"
                ></v-text-field>
              </template>
            </v-range-slider>
          </b-col>
          <b-col></b-col>
        </b-row>
      </b-collapse>
    </b-container>
    <div class="basic-table__table">
      <v-data-table
        :headers="groupHeaders"
        :items="tableData"
        class="elevation-1"
        hide-default-footer
        item-key="index"
        loading-text="데이터 조회중입니다..."
        multi-sort
        show-expand
      >
        <template v-slot:item.count="{ item }">
          {{ getValueData(item.count) + "건" }}
        </template>
        <template v-slot:item.sum="{ item }">
          {{ getValueData(item.sum) }}
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td colspan="1"></td>
          <td colspan="4">
            <v-data-table
              :headers="detailHeaders"
              :items="item.object_list"
              :items-per-page="item.itemsPerPage"
              :page.sync="item.page"
              class="elevation-1"
              hide-default-footer
              item-key="no"
              multi-sort
              show-expand
              @page-count="item.pageCount = $event"
            >
              <template v-slot:item.tran_date="{ item }">
                {{ getDateString(item.tran_date) }}
              </template>
              <template v-slot:item.month="{ item }">
                {{ getMonthString(item.tran_date) }}
              </template>
              <template v-slot:item.diff_month="{ item }">
                {{ getDiffMonthString(item.tran_date) }}
              </template>
              <template v-slot:item.tran_amt="{ item }">
                {{ getValueData(item.tran_amt) }}
              </template>
              <template v-slot:item.after_balance_amt="{ item }">
                {{ getValueData(item.after_balance_amt) }}
              </template>
              <template v-slot:expanded-item="{ headers, item }">
                <td colspan="2"></td>
                <td colspan="4">
                  <v-list dense>
                    <v-list-item>
                      <v-list-item-content class="seize-filter-text"
                        >계좌번호
                      </v-list-item-content>
                      <v-list-item-content class="align-end"
                        >{{ item.account_num }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="seize-filter-text"
                        >거래시간
                      </v-list-item-content>
                      <v-list-item-content class="align-end"
                        >{{ item.tran_date }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="seize-filter-text"
                        >거래유형
                      </v-list-item-content>
                      <v-list-item-content class="align-end"
                        >{{ item.tran_type }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="seize-filter-text"
                        >정보분류 레벨2
                      </v-list-item-content>
                      <v-list-item-content class="align-end"
                        >{{ item.level2 }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="seize-filter-text"
                        >정보분류 레벨3
                      </v-list-item-content>
                      <v-list-item-content class="align-end"
                        >{{ item.level3 }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="seize-filter-text"
                        >정보분류 레벨4
                      </v-list-item-content>
                      <v-list-item-content class="align-end"
                        >{{ item.level4 }}
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item>
                      <v-list-item-content class="seize-filter-text"
                        >정보분류 레벨5
                      </v-list-item-content>
                      <v-list-item-content class="align-end"
                        >{{ item.level5 }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="seize-filter-text"
                        >대상기관명
                      </v-list-item-content>
                      <v-list-item-content class="align-end"
                        >{{ item.target_inst_name }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="seize-filter-text"
                        >성명
                      </v-list-item-content>
                      <v-list-item-content class="align-end"
                        >{{ item.capt_name }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="seize-filter-text"
                        >회차
                      </v-list-item-content>
                      <v-list-item-content class="align-end"
                        >{{ item.capt_round }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="seize-filter-text"
                        >건수
                      </v-list-item-content>
                      <v-list-item-content class="align-end"
                        >{{ item.capt_count }}
                      </v-list-item-content>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-content class="seize-filter-text"
                        >지점
                      </v-list-item-content>
                      <v-list-item-content class="align-end"
                        >{{ item.capt_branch }}
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </td>
                <td colspan="5"></td>
              </template>
            </v-data-table>
            <b-container style="margin-top: 10px; margin-bottom: 10px;">
              <!-- Stack the columns on mobile by making one full-width and the other half-width -->
              <b-row align-v="center">
                <b-col cols="10">
                  <v-pagination
                    v-model="item.page"
                    :length="item.pageCount"
                    :next-icon="'mdi-menu-right'"
                    :prev-icon="'mdi-menu-left'"
                    :total-visible="10"
                  ></v-pagination>
                </b-col>
                <b-col class="text-right" cols="1" style="padding: 0;">
                  <b-form-select
                    v-model="item.itemsPerPage"
                    :options="pageOptions"
                  ></b-form-select>
                </b-col>
                <b-col class="text-right" cols="1" style="padding: 0;">
                  <span>{{ item.total_count }}</span>
                </b-col>
              </b-row>
            </b-container>
          </td>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import * as d3 from "d3";
import numeral from "@/common/utils/numeral";
import SeizePlugin from "@/common/utils/seize";
import Vue from "vue";
import vueMultiSelect from "vue-multi-select";
import "vue-multi-select/dist/lib/vue-multi-select.css";
import DataController from "@/common/utils/data";

Vue.use(SeizePlugin);

export default {
  name: "TransactionDataTable",
  components: {
    vueMultiSelect,
  },
  props: {
    groupHeaders: {
      type: Array,
      default: function () {
        return [
          {
            text: "그룹",
            align: "center",
            class: "seize-table-header",
            sortable: false,
            value: "name",
          },
          {
            text: "입출구분",
            align: "center",
            class: "seize-table-header",
            sortable: false,
            value: "inout_type",
          },
          {
            text: "건수",
            align: "center",
            class: "seize-table-header",
            sortable: false,
            value: "count",
          },
          {
            text: "금액합계",
            align: "right",
            class: "seize-table-header",
            sortable: false,
            value: "sum",
          },
        ];
      },
    },
    group1Function: {
      type: Function,
      default: function (data) {
        return data.inout_tran_type;
      },
    },
    group2Function: {
      type: Function,
      default: function (data) {
        return data.inout_type;
      },
    },
    categoryName: {
      type: String,
      default: function () {
        return "보험업권";
      },
    },
    categoryName2: {
      type: String,
      default: function () {
        return "";
      },
    },
    titleText: String,
    detailData: Array,
    titleTextClass: String,
    inoutInitialFilter: {
      type: Array,
      default: function () {
        return ["입금", "출금"];
      },
    },
    filterDisabled: {
      default: function () {
        return {
          visible: true,
          term: false,
          month: false,
          inout: false,
          targetInst: false,
          name: false,
          level2: false,
          level3: false,
          level4: false,
          level5: false,
          tranType: false,
        };
      },
    },
  },
  data() {
    return {
      pageOptions: [
        { value: 10, text: "10", selected: true },
        { value: 20, text: "20" },
        { value: 30, text: "30" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      singleSelect: false,
      singleExpand: false,
      expanded: ["inout_type"],
      detailHeaders: [
        {
          text: "은행명",
          align: "center",
          class: "seize-table-header",
          value: "bank_name",
        },
        {
          text: "거래일",
          align: "center",
          class: "seize-table-header",
          value: "tran_date",
        },
        {
          text: "월",
          align: "center",
          class: "seize-table-header",
          sortable: false,
          value: "month",
        },
        {
          text: "기간",
          align: "center",
          class: "seize-table-header",
          sortable: false,
          value: "diff_month",
        },
        {
          text: "입출구분",
          align: "center",
          class: "seize-table-header",
          value: "inout_type",
        },
        {
          text: "거래내용",
          align: "start",
          class: "seize-table-header",
          value: "print_content",
        },
        {
          text: "거래금액",
          align: "right",
          class: "seize-table-header",
          value: "tran_amt",
        },
        {
          text: "잔액",
          align: "right",
          class: "seize-table-header",
          value: "after_balance_amt",
        },
        {
          text: "정보분류 설명",
          align: "right",
          class: "seize-table-header",
          value: "level_concat",
        },
      ],
      filter: {
        termLabel: "기간",
        termMode: true,
        term: [],
        month: [],
        inout: this.inoutInitialFilter,
        targetInst: [],
        name: [],
        level2: [],
        level3: [],
        level4: [],
        level5: [],
        tranType: [],
        tranAmt: {
          min: 0,
          max: 10000,
          range: [0, 10000],
        },
      },
      termFilters: [],
      monthFilters: [],
      inoutFilters: ["입금", "출금"],
      targetInstFilters: [],
      nameFilters: [],
      level2Filters: [],
      level3Filters: [],
      level4Filters: [],
      level5Filters: [],
      tranTypeFilters: [],
      printContentFilter: "",
      multiSelectFilters: [
        {
          nameAll: "모두선택",
          nameNotAll: "모두선택취소",
          func() {
            return true;
          },
        },
      ],
      multiSelectOptions: {
        multi: true,
        groups: false,
        cssSelected: (option) =>
          option.selected ? { "background-color": "#3d9dff" } : "",
      },
      selectLabel: function (values) {
        return values.length !== 0
          ? values.length === this.selectOptions.length
            ? "전체"
            : values.length > 4
            ? String(values.length) + "개 선택"
            : String(values)
          : "-";
      },
      dataController: new DataController(),
      baseDate: this.$store.getters.baseDate,
    };
  },
  mounted() {
    this.baseDate = Vue.initBaseDate(this.baseDate);
    if (this.categoryName2.length === 0) {
      this.dataController.setInoutData(
        this.baseDate,
        this.detailData,
        this.categoryName
      );
    } else {
      this.dataController.setInoutData(
        this.baseDate,
        this.detailData,
        this.categoryName,
        this.categoryName2
      );
    }
    this.initFilterSelectData();
  },
  methods: {
    setData(baseDate, dataList) {
      this.baseDate = baseDate;
      this.detailData = dataList;
      if (this.categoryName2.length === 0) {
        this.dataController.setInoutData(
          this.baseDate,
          this.detailData,
          this.categoryName
        );
      } else {
        this.dataController.setInoutData(
          this.baseDate,
          this.detailData,
          this.categoryName,
          this.categoryName2
        );
      }
      this.clearFilterSelectData();
      this.initFilterSelectData();
    },
    clearFilterSelectData() {
      this.filter = {
        termLabel: "기간",
        termMode: true,
        term: [],
        month: [],
        inout: this.inoutInitialFilter,
        targetInst: [],
        name: [],
        level2: [],
        level3: [],
        level4: [],
        level5: [],
        tranType: [],
        tranAmt: {
          min: 0,
          max: 10000,
          range: [0, 10000],
        },
      };
      this.termFilters = [];
      this.monthFilters = [];
      this.inoutFilters = ["입금", "출금"];
      this.targetInstFilters = [];
      this.nameFilters = [];
      this.level2Filters = [];
      this.level3Filters = [];
      this.level4Filters = [];
      this.level5Filters = [];
      this.tranTypeFilters = [];
      this.printContentFilter = "";
      this.multiSelectFilters = [
        {
          nameAll: "모두선택",
          nameNotAll: "모두선택취소",
          func() {
            return true;
          },
        },
      ];
    },
    initFilterSelectData() {
      const baseDate = this.baseDate;
      const termsBaseOutputData = d3
        .nest()
        .key(function (data) {
          return Vue.diffMonthString(data.tran_date, baseDate);
        })
        .entries(this.detailData);
      termsBaseOutputData.forEach((element) => {
        this.termFilters.push(element.key);
        this.filter.term.push(element.key);
      });
      this.termFilters.sort();

      const monthBaseOutputData = d3
        .nest()
        .key(function (data) {
          return Vue.dateToMonthString(data.tran_date);
        })
        .entries(this.detailData);
      monthBaseOutputData.forEach((element) => {
        this.monthFilters.push(element.key);
        this.filter.month.push(element.key);
      });
      this.monthFilters.sort();

      const targetInstBaseOutputData = d3
        .nest()
        .key(function (data) {
          return data.target_inst_name;
        })
        .entries(this.detailData);
      targetInstBaseOutputData.forEach((element) => {
        this.targetInstFilters.push(element.key);
        this.filter.targetInst.push(element.key);
      });
      this.targetInstFilters.sort();

      const nameBaseOutputData = d3
        .nest()
        .key(function (data) {
          return data.capt_name;
        })
        .entries(this.detailData);
      nameBaseOutputData.forEach((element) => {
        this.nameFilters.push(element.key);
        this.filter.name.push(element.key);
      });
      this.nameFilters.sort();

      const level2BaseOutputData = d3
        .nest()
        .key(function (data) {
          return data.level2;
        })
        .entries(this.detailData);
      level2BaseOutputData.forEach((element) => {
        this.level2Filters.push(element.key);
        this.filter.level2.push(element.key);
      });
      this.level2Filters.sort();

      const level3BaseOutputData = d3
        .nest()
        .key(function (data) {
          return data.level3;
        })
        .entries(this.detailData);
      level3BaseOutputData.forEach((element) => {
        this.level3Filters.push(element.key);
        this.filter.level3.push(element.key);
      });
      this.level3Filters.sort();

      const level4BaseOutputData = d3
        .nest()
        .key(function (data) {
          return data.level4;
        })
        .entries(this.detailData);
      level4BaseOutputData.forEach((element) => {
        this.level4Filters.push(element.key);
        this.filter.level4.push(element.key);
      });
      this.level4Filters.sort();

      const level5BaseOutputData = d3
        .nest()
        .key(function (data) {
          return data.level5;
        })
        .entries(this.detailData);
      level5BaseOutputData.forEach((element) => {
        this.level5Filters.push(element.key);
        this.filter.level5.push(element.key);
      });
      this.level5Filters.sort();

      const tranTypeBaseOutputData = d3
        .nest()
        .key(function (data) {
          return data.tran_type;
        })
        .entries(this.detailData);
      tranTypeBaseOutputData.forEach((element) => {
        this.tranTypeFilters.push(element.key);
        this.filter.tranType.push(element.key);
      });
      this.tranTypeFilters.sort();
      let min = Number.MAX_SAFE_INTEGER;
      let max = Number.MIN_SAFE_INTEGER;
      this.detailData.forEach((element) => {
        if (min > element.tran_amt) {
          min = element.tran_amt;
        }
        if (max < element.tran_amt) {
          max = element.tran_amt;
        }
      });
      if (min > 0) {
        min = 0;
      }
      if (max < 0) {
        max = 1000000;
      }
      this.filter.tranAmt.min = min;
      this.filter.tranAmt.max = max;
      this.filter.tranAmt.range = [min, max];
    },
    getDateString(date) {
      return Vue.dateToDateString(date);
    },
    getMonthString(date) {
      return Vue.dateToMonthString(date);
    },
    getDiffMonthString(date) {
      return Vue.diffMonthString(date, this.baseDate);
    },
    getValueData(value) {
      return numeral(value).format("0,0");
    },
    changeTermMode() {
      if (this.filter.termMode) {
        this.filter.termMode = false;
        this.filter.termLabel = "월";
      } else {
        this.filter.termMode = true;
        this.filter.termLabel = "기간";
      }
    },
  },
  computed: {
    amount_max: {
      get() {
        return numeral(this.filter.tranAmt.range[1]).format("0,0");
      },
      set(value) {
        this.filter.tranAmt.range[1] = value;
      },
    },
    tableData() {
      return this.dataController.filteredGroupingList(
        this.filter,
        this.printContentFilter,
        this.group1Function,
        this.group2Function
      );
    },
  },
};
</script>
