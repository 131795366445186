<template>
  <div class="basic-table" style="padding-right: 10px;">
    <div class="row basic-table__header no-gutters align-items-center">
      <div
        v-if="!isHaveLink"
        class="col"
        style="padding-top: 9px; padding-bottom: 9px;"
      >
        <h3 class="basic-table__title" v-bind:class="titleColorClass">
          출금 거래
        </h3>
      </div>
      <div
        v-if="isHaveLink"
        class="col"
        style="padding-top: 9px; padding-bottom: 9px;"
      >
        <h3 class="basic-table__title" v-bind:class="titleColorClass">
          출금 거래
        </h3>
      </div>
    </div>
    <div class="basic-table__table">
      <v-data-table
        :headers="headers"
        :items="tableData"
        class="seize-table-box"
        dense
        hide-default-footer
        item-key="key"
        loading-text="데이터 조회중입니다..."
      >
        <template v-slot:item.key="{ item }">
          <span style="color: #000000; font-weight: bold;">{{ item.key }}</span>
        </template>
        <template v-slot:item.count="{ item }">
          <span style="color: #000000;">{{ item.count }}</span>
        </template>
        <template v-slot:item.sum="{ item }">
          <span style="color: #000000;">{{ item.sum }}</span>
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    titleColorClassString: String,
    titleButtonLink: String,
    titleButtonVariant: String,
    titleButtonClass: String,
    outputData: Array,
    isHaveLink: {
      type: Boolean,
      default: function () {
        return false;
      },
    },
  },
  data() {
    return {
      headers: [
        {
          text: "기간",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "key",
        },
        {
          text: "건수",
          sortable: false,
          align: "center",
          class: "seize-table-header seize-table-box",
          value: "count",
        },
        {
          text: "합계",
          sortable: false,
          align: "right",
          class: "seize-table-header seize-table-box",
          value: "sum",
        },
      ],
    };
  },
  mounted() {},
  methods: {},
  computed: {
    titleColorClass() {
      return this.titleColorClassString;
    },
    tableData() {
      return this.outputData;
    },
  },
};
</script>
