import * as d3 from "d3";
import code from "@/common/mock/code.json";

export default class DataController {
  constructor() {
    this.rawData = [];
  }
  setIncomeInfoData(baseDate, rawData) {
    DataController.#addAdditionalInfo(baseDate, rawData);
    const categoryData = rawData.filter((data) => data.inout_code === 1);
    this.baseDate = baseDate;
    this.rawData = this.rawData.concat(categoryData);
  }
  setPayInfoData(baseDate, rawData, categoryName) {
    DataController.#addAdditionalInfo(baseDate, rawData);
    const categoryData = rawData.filter(
      (data) => data.payment_history === categoryName
    );
    this.baseDate = baseDate;
    this.rawData = this.rawData.concat(categoryData);
  }
  setInoutData(baseDate, rawData, categoryName, categoryName2 = null) {
    this.rawData.length = 0;
    DataController.#addAdditionalInfo(baseDate, rawData);
    const categoryData = rawData.filter((data) =>
      categoryName2
        ? data.inout_tran === categoryName &&
          data.inout_tran_type === categoryName2
        : data.inout_tran === categoryName
    );
    this.baseDate = baseDate;
    this.rawData = this.rawData.concat(categoryData);
  }
  filteredGroupingList(
    filter,
    printContentFilter,
    group1Function,
    group2Function
  ) {
    const filteredData = this.filteredList(filter, printContentFilter);
    const groupResult = d3
      .nest()
      .key(group1Function)
      .key(group2Function)
      .rollup(function (values) {
        return {
          count: d3.sum(values, function (data) {
            return 1;
          }),
          sum: d3.sum(values, function (data) {
            return data.tran_amt;
          }),
          objects: values,
        };
      })
      .entries(filteredData);
    groupResult.sort(function (a, b) {
      const compareDict = {
        null: 0,
        보험: 3,
        대출: 2,
        기타: 1,
        예적금: 3,
        개인: 3,
        "주식/유한": 2,
        카드대금: 3,
        캐피탈: 3,
        저축은행: 2,
        대부: 1,
        공제업권: 3,
        "증권/자산운용": 2,
        특수업권: 1,
        특수활동: 3,
        생활: 2,
        "기타/미분류": 1,
        입금: 2,
        출금: 1,
      };
      if (compareDict[a.key] && compareDict[b.key]) {
        return compareDict[a.key] > compareDict[b.key]
          ? -1
          : compareDict[a.key] < compareDict[b.key]
          ? 1
          : compareDict[a.value.key] > compareDict[b.value.key]
          ? -1
          : compareDict[a.value.key] < compareDict[b.value.key]
          ? 1
          : 0;
      } else {
        return 0;
      }
    });
    const result = [];
    let count = 0;
    groupResult.forEach((element) => {
      element.values.forEach((item) => {
        const itemValue = {};
        itemValue["index"] = count;
        itemValue["name"] = element.key;
        itemValue["inout_type"] = item.key;
        itemValue["count"] = item.value.count;
        itemValue["sum"] = item.value.sum;
        itemValue["object_list"] = item.value.objects;
        itemValue["page"] = 1;
        itemValue["itemsPerPage"] = 10;
        itemValue["pageCount"] = 0;
        itemValue["total_count"] =
          "총 " + String(item.value.objects.length) + "개 데이터";
        count += 1;
        result.push(itemValue);
      });
    });
    return result;
  }

  filteredList(filter, printContentFilter) {
    const filteredData = this.rawData.filter(
      (data) =>
        (filter.termMode
          ? filter.term.includes(data.diff_month)
          : filter.month.includes(data.month_string)) &&
        filter.inout.includes(data.inout_type) &&
        (data.target_inst_name
          ? filter.targetInst.includes(data.target_inst_name)
          : filter.targetInst.includes("null")) &&
        (data.capt_name
          ? filter.name.includes(data.capt_name)
          : filter.name.includes("null")) &&
        (data.level2
          ? filter.level2.includes(data.level2)
          : filter.level2.includes("null")) &&
        (data.level3
          ? filter.level3.includes(data.level3)
          : filter.level3.includes("null")) &&
        (data.level4
          ? filter.level4.includes(data.level4)
          : filter.level4.includes("null")) &&
        (data.level5
          ? filter.level5.includes(data.level5)
          : filter.level5.includes("null")) &&
        (data.tran_type
          ? filter.tranType.includes(data.tran_type)
          : filter.tranType.includes("null")) &&
        data.tran_amt >= filter.tranAmt.range[0] &&
        data.tran_amt <= filter.tranAmt.range[1] &&
        (printContentFilter.length !== 0
          ? data.print_content
            ? data.print_content.includes(printContentFilter)
            : false
          : true)
    );
    return filteredData;
  }
  static #getCodeDictionary() {
    const result = {};
    code.forEach((element) => {
      const data = {};
      data["주요정보"] = element["주요정보"];
      data["납부이력"] = element["납부이력"];
      data["입출거래"] = element["입출거래"];
      data["입출거래_구분"] = element["입출거래_구분"];
      result[element["category_code1"]] = data;
    });
    return result;
  }
  static #dateToDateString(date, splitter = "-") {
    if (!date) {
      return "-";
    }
    if (typeof date === "string") {
      date = date.replace(/[.-]/gi, "/");
    }
    const d = new Date(date);
    const year = d.getFullYear();
    let month = (d.getMonth() + 1).toString();
    let day = d.getDate().toString();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join(splitter);
  }
  static #getBeforeMonthString(baseDate, count) {
    if (count === 0) {
      return DataController.#dateToDateString(baseDate);
    }
    while (count !== 0) {
      if (typeof baseDate === "string") {
        baseDate = baseDate.replace(/[.-]/gi, "/");
      }
      let today = new Date(baseDate);
      today.setMonth(today.getMonth() - 1);

      baseDate = DataController.#dateToDateString(today);
      count -= 1;
    }
    return DataController.#dateToDateString(baseDate);
  }
  static #monthString(date) {
    if (!date) {
      return "-";
    }
    if (typeof date === "string") {
      date = date.replace(/[.-]/gi, "/");
    }
    const d = new Date(date);
    let month = (d.getMonth() + 1).toString();
    return month + "월";
  }
  static #diffMonthString(date1, date2) {
    let d1 = DataController.#dateToDateString(date1);
    let d2 = DataController.#dateToDateString(date2);
    if (d1 > d2) {
      return "-";
    } else if (d1 === d2) {
      return "1M";
    } else {
      let count = 0;
      while (d2 >= d1) {
        d2 = DataController.#getBeforeMonthString(d2, 1);
        count += 1;
        if (d1 >= d2) {
          break;
        }
      }
      return String(count) + "M";
    }
  }
  static #addAdditionalInfo(baseDate, rawData) {
    const codeDict = DataController.#getCodeDictionary();
    let id = 0;
    rawData.forEach((element) => {
      if (codeDict[element.category_code1]) {
        element["id"] = id;
        id += 1;
        element["month_string"] = DataController.#monthString(
          element.tran_date
        );
        element["diff_month"] = DataController.#diffMonthString(
          element.tran_date,
          baseDate
        );
        element["major_info"] = codeDict[element.category_code1]["주요정보"];
        element["payment_history"] =
          codeDict[element.category_code1]["납부이력"];
        element["inout_tran"] = codeDict[element.category_code1]["입출거래"];
        element["inout_tran_type"] =
          codeDict[element.category_code1]["입출거래_구분"];
      }
    });
  }
}
