<template>
  <div>
    <CardReport ref="report" v-bind:baseDate="baseDate"></CardReport>
    <div class="row">
      <div class="col-xl-12 col-lg-12 order-lg-2 order-xl-1">
        <KTPortlet v-bind:class="'kt-portlet--height-fluid'">
          <template v-slot:body>
            <GroupingTransactionDataTable
              ref="inoutTable"
              v-bind="{
                baseDate: baseDate,
                titleText: '카드업권 입출거래 상세',
                titleTextClass: 'text-seizeblue',
                detailData: detailInputDataArray,
                categoryName: '카드업권',
                filterDisabled: {
                  term: false,
                  inout: false,
                  targetInst: false,
                  name: false,
                  level2: false,
                  level3: false,
                  level4: false,
                  level5: false,
                  tranType: false,
                },
                inoutInitialFilter: ['입금', '출금'],
                itemsPerPage: 10,
              }"
            >
            </GroupingTransactionDataTable>
          </template>
        </KTPortlet>
      </div>
    </div>
  </div>
</template>

<script>
import KTPortlet from "@/views/partials/content/Portlet.vue";
import GroupingTransactionDataTable from "@/views/partials/widgets/tables/GroupingTransactionDataTable.vue";
import CardReport from "@/views/partials/widgets/reports/CardReport";

export default {
  name: "Card",
  components: {
    CardReport,
    KTPortlet,
    GroupingTransactionDataTable,
  },
  data() {
    return {
      baseDate: this.$store.getters.baseDate,
      detailInputDataArray: this.$store.getters.requestItem.items,
    };
  },
  mounted() {
    document.title = "카드업권";
  },
  methods: {
    updateView() {
      this.baseDate = this.$store.getters.baseDate;
      this.detailInputDataArray = this.$store.getters.requestItem.items;
      this.$refs.report.updateView();
      this.$refs.inoutTable.setData(this.baseDate, this.detailInputDataArray);
    },
    setActiveTab(event) {
      // get all tab links
      const tab = event.target.closest('[role="tablist"]');
      const links = tab.querySelectorAll(".nav-link");
      // remove active tab links
      for (let i = 0; i < links.length; i++) {
        links[i].classList.remove("active");
      }

      // set current active tab
      event.target.classList.add("active");

      // set clicked tab index to bootstrap tab
      return parseInt(event.target.getAttribute("data-tab"));
    },
  },
};
</script>
